<template>
  <div style="width: 100%; height: 100%">
    <h-table
      ref="table"
      :data="table.data"
      :columns="tableColumns"
      :loading="table.loading"
      :options="options"
      @page-change="pageChange"
      @search="search"
    >
    </h-table>
    <order-modal
      :farms="farms"
      :supply-demand="orderModalData.supplyDemand"
      v-model="orderModalData.show"
      :title="orderModalData.title"
    >
    </order-modal>
  </div>
</template>
<script>
import orderModal from "./orderModal";

export default {
  props: {
    type: String,
  },
  components: {
    orderModal,
  },
  data() {
    return {
      // 订单表单弹窗管理
      orderModalData: {
        show: false,
        title: "",
        supplyDemand: null,
      },
      table: {
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      // 查询数据
      searchModel: {
        demandStatus: "1",
      },
      // 查询数据配置项
      searchRule: {
        demandStatus: {
          name: "状态",
          type: "s",
          data: [
            {
              value: "1",
              label: "发布中",
            },
            {
              value: "2",
              label: "已结束",
            },
          ],
        },
      },
      // 表单数据
      formModel: {
        demandName: "", //标题
        // productId: "", //产品
        demandDesc: "", //描述
        // demandRemark: "", //备注
        // demandNum: "", //数量
        // demandUnit: "", //单位
        demandImgs: "", //图片
        publisherFarmId: "", //基地
        // demandAddress: "", //地址
        telName: "", //联系人
        telPhone: "", //联系电话
      },
      // 表单配置项
      formRules: {
        demandName: {
          name: this.type == "demand" ? "需求标题" : "供应标题",
          type: "t",
          required: true,
          col: 24,
        },
        demandDesc: {
          name: "货品描述",
          type: "t",
          col: 24,
        },
        // productId: {
        //   name: "产品名称",
        //   type: "s",
        //   required: true,
        //   data: this.getProducts,
        // },
        publisherFarmId: {
          type: "s",
          name: this.type == "demand" ? "收货地点" : "发货地点",
          onChange: this.fillAddress,
          required: true,
          data: this.getFarms,
        },
        // demandAddress: {
        //   type: "i",
        //   name: this.type == "demand" ? "收货地址" : "发货地址",
        // },
        // demandNum: {
        //   name: "货品数量",
        //   type: "i",
        //   required: true,
        //   rules: [
        //     {
        //       validator: (rule, value, callback) => {
        //         if (CU.validateNumber(value)) callback();
        //         else return callback(rule.message);
        //       },
        //       message: "请输入数字",
        //     },
        //   ],
        // },
        // demandUnit: {
        //   name: "单位",
        //   required: true,
        //   type: "i",
        // },
        telName: {
          name: "联系人",
          // required: true,
          type: "i",
        },
        telPhone: {
          name: "联系电话",
          // required: true,
          type: "i",
        },
        demandImgs: {
          type: "image",
          name: "上传图片",
        },
        // demandRemark: {
        //   name: "备注",
        //   col: 24,
        //   type: "t",
        // },
      },
      formLoading: false,
      // 产品列表
      products: [],
      // 基地列表
      farms: [],
    };
  },
  computed: {
    tableColumns() {
      let prefix = this.type == "demand" ? "需求" : "供应";
      return [
        {
          title: prefix + "标题",
          minWidth: 100,
          key: "demandName",
        },
        // {
        //   title: "货物名称",
        //   minWidth: 100,
        //   key: "productName",
        // },
        // {
        //   title: prefix + "数量",
        //   minWidth: 100,
        //   key: "demandNum",
        //   render: (h, { row }) => {
        //     let str = row.demandNum + row.demandUnit;
        //     return <span>{str}</span>;
        //   },
        // },
        // {
        //   title: this.type == "demand" ? "收货地址" : "发货地址",
        //   minWidth: 100,
        //   key: "demandAddress",
        // },
        {
          title: "联系人",
          minWidth: 100,
          key: "telName",
        },
        {
          title: "联系电话",
          minWidth: 120,
          key: "telPhone",
        },
        {
          title: "状态",
          minWidth: 100,
          render: (h, { row }) => {
            return <span>{row.demandStatus == "1" ? "发布中" : "已完成"}</span>;
          },
        },
        {
          title: "操作",
          width: 200,
          render: (h, { row }) => {
            return (
              <div>
                {
                  <a
                    style="margin-right: 10px"
                    on-click={() => this.check(row)}
                  >
                    需求详情
                  </a>
                }
                {localStorage.userId != row.createUserId &&
                  row.demandStatus != "2" && (
                    <a
                      on-click={() => this.addOrder(row)}
                      style="margin-right: 10px"
                    >
                      下单
                    </a>
                  )}
                {localStorage.userId != row.createUserId && (
                  <a style="margin-right: 10px">沟通</a>
                )}
                {localStorage.userId == row.createUserId &&
                  row.demandStatus == "1" && (
                    <Poptip
                      on-on-ok={() => this.deleteRow(row)}
                      confirm
                      transfer
                      title="确定撤销吗?"
                    >
                      <a style="margin-right: 10px">撤销</a>
                    </Poptip>
                  )}
              </div>
            );
          },
        },
      ];
    },
    options() {
      return {
        page: this.page,
        search: {
          model: this.searchModel,
          rules: this.searchRule,
        },
        add: {
          text: this.type == "demand" ? "新增需求信息" : "新增供应信息",
          ca: "supply_add",
          submit: this.add,
        },
        check: {
          text: this.type == "demand" ? "查看需求信息" : "查看供应信息",
        },
        form: {
          loading: this.formLoading,
          width: 720,
          col: 12,
          model: this.formModel,
          rules: this.formRules,
        },
      };
    },
  },
  methods: {
    add(callback) {
      let params = {
        ...this.formModel,
      };
      // let product = this.products.find(
      //   (item) => item.value == params.productId
      // );
      // params.productName = product.label;
      params.demandType = this.type === "demand" ? "1" : "0";
      params.demandImgs = params.demandImgs
        .split(",")
        .map((item) => {
          let index = item.indexOf("/", 10);
          return item.substring(index + 1);
        })
        .join(",");
      this.formLoading = true;
      this.$post(this.$api.SUPPLY_BIND.ADD, params)
        .then(() => {
          this.$emit("发布成功");
          this.getList();
          callback();
        })
        .catch((e) => {
          this.$Message.warning(e);
          callback(false);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    // 根据基地填充地址
    fillAddress(id) {
      if (!id) return;
      let farm = this.farms.find((item) => item.id === id);
      this.formModel.demandAddress = farm.address;
    },
    // 撤销供需
    deleteRow(row) {
      this.$post(this.$api.SUPPLY_BIND.DELETE, {
        id: row.id,
      }).then(() => {
        this.$Message.success("撤销成功");
        this.getList();
      });
    },
    // 查看供需
    check(row) {
      for (let key of Object.keys(this.formModel)) {
        this.formModel[key] = row[key];
      }
      this.formModel.productId = +row.productId;
      this.formModel.demandImgs = row.demandImgs
        .split(",")
        .map((item) => row.servicePath + item)
        .join(",");
      this.$refs.table.check();
    },
    // 下单
    addOrder(row) {
      this.orderModalData.supplyDemand = { ...row };
      this.orderModalData.title = "发起订单";
      this.orderModalData.show = true;
    },
    search() {
      this.page.pageNo = 1;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    // 获取基地
    getFarms() {
      return new Promise((resolve) => {
        this.$post(this.$api.BASE_MANAGE.LIST)
          .then((res) => {
            this.farms = res.list;
            let result = res.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            resolve(result);
          })
          .catch((e) => {
            this.farms = [];
            resolve([]);
          });
      });
    },
    // 查询产品列表
    getProducts() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCT_INFO.LIST)
          .then((res) => {
            let result = res.list.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            this.products = result;
            resolve(result);
          })
          .catch((e) => {
            this.products = [];
            resolve([]);
          });
      });
    },
    //   查询列表
    getList() {
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        ...this.searchModel,
        demandType: this.type == "demand" ? "1" : "0",
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.table.data = res.list;
        this.page.total = +res.total;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>