<template>
  <h-modal
    :loading="loading"
    @on-ok="submit"
    :width="700"
    title="发起订单"
    :value="value"
    @input="(val) => $emit('input', val)"
  >
    <Form ref="form" :model="model" :rules="rules" :label-width="85">
      <Row>
        <Col :span="12">
          <FormItem prop="name" label="订单标题">
            <Input v-model="model.name"></Input>
          </FormItem>
        </Col>
        <Col :span="12">
          <FormItem
            prop="orderFarmId"
            :label="type == 'demand' ? '发货地点' : '收货地点'"
          >
            <Select v-model="model.orderFarmId">
              <Option v-for="farm in farms" :key="farm.id" :value="farm.id">
                {{ farm.name }}
              </Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <FormItem prop="remark" label="备注">
        <Input :rows="4" type="textarea" v-model="model.remark"></Input>
      </FormItem>
    </Form>
    <div>
      <div
        style="
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 12px;
        "
      >
        订单货品列表
      </div>
      <Table border :columns="tableColumns" :data="tableData"> </Table>
    </div>
  </h-modal>
</template>
<script>
import { Input } from "view-design";
import CU from "@/common/util";

export default {
  props: {
    farms: {
      type: Array,
      default() {
        return [];
      },
    },
    supplyDemand: Object,
    value: Boolean,
  },
  data() {
    return {
      type: "demand",
      loading: false,
      model: {
        // orderNo: "",
        name: "",
        // orderType: "",
        remark: "",
        orderFarmId: "",
      },
      staticModel: {
        orderNo: "",
        orderType: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写订单标题",
          },
        ],
        orderFarmId: [
          {
            required: true,
            message: "请选择收货地点",
          },
        ],
      },
      tableColumns: [
        {
          title: "货品名称",
          render: (h, { row }) => {
            return <span>{row.productName}</span>;
          },
        },
        {
          title: "订单数量",
          render: (h, { row, index }) => {
            let iserror = this.errorNumRow.has(row.productId);
            return (
              <span class={iserror ? "ivu-form-item-error" : ""}>
                <Input
                  on-input={(val) => this.validateNum(val, row, index)}
                  value={row.productNum}
                  size="small"
                ></Input>
              </span>
            );
          },
        },
        {
          title: "货品单位",
          render: (h, { row, index }) => {
            let iserror = this.errorUnitRow.has(row.productId);
            return (
              <span class={iserror ? "ivu-form-item-error" : ""}>
                <Input
                  on-input={(val) => this.validateUnit(val, row, index)}
                  value={row.productUnit}
                  size="small"
                ></Input>
              </span>
            );
          },
        },
      ],
      tableData: [],
      // 验证订单数量未通过的行id
      errorNumRow: new Set(),
      //验证订单单位未通过的行id
      errorUnitRow: new Set(),
    };
  },
  watch: {
    value(val) {
      if (val) return;
      this.$refs.form.resetFields();
      this.staticModel = {
        orderNo: "",
        orderType: "",
      };
      this.tableData = [];
      this.errorUnitRow.clear();
      this.errorNumRow.clear();
    },
    supplyDemand: {
      handler(obj) {
        if (!obj)
          this.staticModel = {
            orderNo: "",
            orderType: "",
          };
        else {
          this.model.name = obj.demandName;
          this.tableData = [
            {
              productId: obj.productId,
              productName: obj.productName,
              productNum: obj.demandNum,
              productUnit: obj.demandUnit,
            },
          ];
          this.staticModel = {
            orderNo: obj.id,
            orderType: +obj.demandType + 1,
          };
          this.type = obj.demandType == 1 ? "demand" : "supply";
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 验证货品数量
    validateNum(val, row, index) {
      if (CU.validateNumber(val, 0.01)) {
        this.errorNumRow.has(row.productId) &&
          this.errorNumRow.delete(row.productId);
      } else {
        this.errorNumRow.add(row.productId);
      }
      let newRow = {
        ...row,
        productNum: val,
      };
      this.tableData.splice(index, 1, newRow);
    },
    // 验证货品单位是否为空
    validateUnit(val, row, index) {
      let flag = false;
      if (!val) flag = true;
      if (typeof val == "string" && !val.trim()) flag = true;
      if (flag) {
        this.errorUnitRow.add(row.productId);
      } else {
        this.errorUnitRow.has(row.productId) &&
          this.errorUnitRow.delete(row.productId);
      }
      let newRow = {
        ...row,
        productUnit: val,
      };
      this.tableData.splice(index, 1, newRow);
    },
    submit() {
      this.$refs.form.validate((result) => {
        if (!result) return;
        if (this.errorNumRow.size > 0) return;
        if (this.errorUnitRow.size > 0) return;
        let params = {
          ...this.model,
          ...this.staticModel,
          itemList: this.tableData,
        };
        console.log(params);
        this.loading = true;
        this.$post(this.$api.ORDER_INFO.ADD, params)
          .then((res) => {
            this.$Message.success(
              "订单已发出！关注订单状态，请前往订单管理页面"
            );
            this.$emit("input", false);
          })
          .finally(() => (this.loading = false));
      });
    },
  },
};
</script>